<template>
  <div class="login">
    <div
      class="rounded-t-lg overflow-hidden border-t border-l border-r border-gray-400 px-3 py-10 bg-gray-200 flex justify-center"
    >
      <div class="w-full max-w-xs">
        <form class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
          <div class="mb-4">
            <label
              class="block text-gray-700 text-sm font-bold mb-2"
              for="email"
            >
              Email
            </label>
            <input
              ref="email"
              class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="email"
              v-model="email"
              type="text"
              placeholder="Email Address"
            />
          </div>
          <transition name="fade" mode="out-in">
            <div class="mb-6" v-if="!passwordReset">
              <label
                class="block text-gray-700 text-sm font-bold mb-2"
                for="password"
              >
                Password
              </label>
              <input
                ref="password"
                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                :class="{ 'border-red-500': !passwordValid }"
                id="password"
                v-model="password"
                type="password"
                placeholder="******************"
              />
              <p
                :class="{ 'text-red-500': !passwordValid }"
                class="text-xs italic"
              >
                Please enter password.
              </p>
            </div>
          </transition>
          <div class="flex items-center justify-between">
            <button
              class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="button"
              @click="signIn"
            >
              {{ passwordReset ? "Reset Password" : "Sign In" }}
            </button>
            <a
              @click="forgotPassword"
              class="inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800"
              href="#"
            >
              {{ passwordReset ? "Login?" : "Forgot Password?" }}
            </a>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
  components: {},
  mounted() {
    //this.$store.dispatch('user/getAllProducts');
    /*     var fullName = functions.httpsCallable("fullName");
        //For the fullName we have defined that fullName takes some data as a parameter
        fullName({
        firstName: "bilbo",
        lastName: "baggins"
        })
        .then(result => {
            console.log(result);
        })
        .catch(error => {
            console.log(error);
        }); */
    /*     console.log(auth);
        let email = "trevordowdle@gmail.com";
        let password = "joejoe"; */
    /*     auth.signInWithEmailAndPassword(email, password)
        .then((user)=>{
        debugger;
        //this.setLoggedInUserAsAdmin()
        this.createUser();
        })
        .catch(function(error) {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        debugger;
        // ...
        }); */
  },
  data: function() {
    return {
      emailValid: true,
      passwordValid: true,
      passwordReset: false,
      email: "",
      password: ""
    };
  },
  methods: {
    forgotPassword() {
      this.passwordReset = !this.passwordReset;
    },
    signIn() {
      if (!this.email) {
        this.$refs.email.focus();
        return false;
      }
      if (!this.passwordReset) {
        if (!this.password) {
          this.passwordValid = false;
          return false;
        }
        this.$store
          .dispatch("user/login", {
            email: this.email,
            password: this.password
          })
          .then(result => {
            console.log(result);
            this.$nextTick(() => {
              this.$router.push(this.$route.query.redirect || "/");
            });
          })
          .catch(function(error) {
            console.log(error);
            if (error.code === "auth/wrong-password") {
              alert("wrong password, please try again");
            } else if (error.code === "auth/user-not-found") {
              alert("No user for this email exists.");
            } else {
              console.log(error);
              alert("error");
            }
          });
      } else {
        this.$store
          .dispatch("user/passwordReset", this.email)
          .then(result => {
            alert('Password reset email sent.');
            this.passwordReset = false;
          })
          .catch(function(error) {
            console.log(error);
            debugger;
            // An error happened.
          });
      }

      //this.$refs.password.focus();
    },
    setLoggedInUserAsAdmin() {
      //var setAdmin = functions.httpsCallable("setLoggedInUserAsAdmin");
      //setAdmin();
    },
    createUser() {
      /*       var createUser = functions.httpsCallable("createUser");
        //For the fullName we have defined that fullName takes some data as a parameter
        createUser({
            firstName: "trevor",
            lastName: "dowdle",
            password: "joejoe",
            email: "tdowdle@gmail.com",
            role: "user"
        })
            .then(result => {
            console.log(result);
            debugger;
            })
            .catch(error => {
            console.log(error.code);
            console.log(error.message);
            debugger;
            }); */
    }
  }
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter, .fade-leave-to
    /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
